import React from 'react'
import MainRouter from './MainRouter';
import Entete from './Entete';
/**
* @author
* @function 
**/
const StartApp = () => {
return(
    <div>
        <Entete/>
        <MainRouter/>
    </div>
   )
  }
export default StartApp