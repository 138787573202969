import moment from "moment";
import React, {useState,forwardRef,useEffect}from 'react';
//import MaterialTable, { MTableBodyRow } from "material-table";
import MaterialTable, { MTableBodyRow,MTableToolbar }  from '@material-table/core';
import { Grid,Typography, Button} from '@mui/material';
import AddBox from '@mui/icons-material/AddBox';
import Check from '@mui/icons-material/Check';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Clear from '@mui/icons-material/Clear';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
//import DeleteIcon from "@mui/icons-material/Delete";
import Edit from '@mui/icons-material/Edit';
import FilterList from '@mui/icons-material/FilterList';
import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';
import Remove from '@mui/icons-material/Remove';
import SaveAlt from '@mui/icons-material/SaveAlt';
import Search from '@mui/icons-material/Search';
import ViewColumn from '@mui/icons-material/ViewColumn';
import { useQuery,useMutation ,useQueryClient } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import * as DonsDbService from '../../../DbServices/dons.dbServices'
import './DonsMainForm.css'

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownwardIcon {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };

const DonsMainForm = () => {
    const location = useLocation();
    const Patrenaire = location.state;
    const ForOne = Patrenaire.cuDonateur >0
    const navigate = useNavigate();
    const queryClient = useQueryClient()
    const [LesTypesDons,setLesTypesDons]=useState({})
    const [LesTypesVal,setLesTypesVal]=useState({})
    const [ListUser,setListUser]=useState({})

   
  
    useEffect(() => {  
      const dataTypesDons =queryClient.getQueryData("TypeDons")
      const dataTypesVal  =queryClient.getQueryData("TypeVal");
      const dataUsersList =queryClient.getQueryData("LesUsers"); 
      console.log("dataUsersList ",dataUsersList)
      if (dataUsersList) {
        let obj = dataUsersList.reduce(function(acc, cur, i){ 
          acc[cur.CuUser] = cur.Nom; return acc; 
        }, {});
        console.log("dataUsersList Obj ",obj)
        setListUser(obj)
      }
      if (dataTypesVal){
          let obj = dataTypesVal.reduce(function(acc, cur, i){
            acc[cur.cuTypesValeur] = cur.designation; return acc; 
            }, {});
          setLesTypesVal(obj)
      }
      if (dataTypesDons){
          let obj = dataTypesDons.reduce(function(acc, cur, i){
            acc[cur.cuTypesDon] = cur.designation; return acc; 
            }, {});
          setLesTypesDons(obj)
          console.log(" les type don ",obj)
          }
          
    },[Patrenaire,queryClient])
  console.log(' LesTypesVal= ',LesTypesVal,'LesTypesDons= ',LesTypesDons)
    const columns=[
        {title: "cuDon", field: "cuDon", hidden: true},
        {title: "DonateurCu", field: "DonateurCu", hidden: true},
        {title: "Partenaire", field: "nomDonateur", hidden: ForOne},
        {title: "Date du don", field: "ladate",type: "date"},
        {title: "Type de Don", field: "typeDon",lookup:LesTypesDons},
        {title: "Motif du don", field: "motif"},
        {title: "Valeur du don", field: "Valeur"},
        {title: "Type de Valeur", field: "typeValeur", lookup:LesTypesVal},
        {title: "Reçu Par", field: "receptionner", lookup: ListUser},
        {title: "Observation ", field: "observation"},
        {title: "userCu", field: "userCu", hidden: true},
        
        ]
       /*
        const OnAddNewRow =async (rowData)=>{
        }
        const OnEditRow =(rowData)=>{ 

        }
        const OnDelete=async (rowData)=>{
        }*/
        
        let Param={
            "ForOne":ForOne,
            "DonateurCu":Patrenaire.cuDonateur
        }
        const {data, status } = useQuery(["Dons",Param], DonsDbService.GetAllDons,
              {
                staleTime: 60 * 1000, 
                cacheTime: 60 * 1000
              });
        console.log("DonsMainForm Data ",data)

      
      const UpdateMutation = useMutation((dataToUpdate) => 
      DonsDbService.EditDon(dataToUpdate),
          {
            onSuccess: () => queryClient.invalidateQueries("Dons"),
          }
        );
      const AddMutation =  useMutation( newRecord => 

          DonsDbService.AddDon(newRecord), 
          {
              onSuccess: () => queryClient.invalidateQueries("Dons"),
          }
        )  

      const DeleteMutationRow = useMutation(
        (dataToDelate) =>DonsDbService.DelDon(dataToDelate),
        {
          onSuccess: () => {
            queryClient.invalidateQueries("Dons");
          },
        }
      );
    return (
    <div>
       {/* <Grid item xs={1}></Grid> Première colonne vide */}
        <Grid container spacing={1} direction="column" justifyContent="center" alignItems="center">
        <Grid container  direction="column" justifyContent="flex-end" alignItems="flex-end">
          <div>
              <Button variant="contained" size="small" color="primary" 
                onClick={() => navigate(-1) }> 
                Retour à l'écran des partenaires
             </Button>
          </div>
            
            </Grid>
            {(() => {
                if (ForOne) {
                    return <Typography variant="h6">Les dons du Partenaire : <span  className="titrePartenaire">{Patrenaire.nomDonateur}</span></Typography>
                }
                return <Typography variant="h6">Liste de tous les dons</Typography>
                })()}
            
            <Grid >

            {status === "error" && <div>Une Erreur s'est produite: {data.error}</div>}
            {status === "loading" &&  <div> <h2>Chargement en cours...</h2> </div>}
            {status === "success" &&     
            <MaterialTable
                    title=  {""}
                    columns={columns}
                    data={data}
                    icons={tableIcons}
                    onRowClick={(async (evt, selectedRow) => { 
                      console.log("selectedRow ",selectedRow)
                     //Afficher la boite de dialogue ou le render detail compoment

                      //
                    // dispatch(getDetails(selectedRow.idMouvement));                     
                      })
                      }
                    options={{
                        exportButton: true,
                        pageSize: 5,
                        pageSizeOptions: [5, 10],
                        headerStyle: {
                            backgroundColor: "#800b00d2",
                            color: "#FFF",
                            fontSize: "17px",
                            textAlign: "center",
                            fontWeight: "bold",
                            borderRight: '1px solid #eee'
                          },
                        cellStyle: { borderRight: '1px solid #eee' }  
                      }}
                      editable={{
                         onRowAdd: newData =>
                          new Promise((resolve, reject) => {
                            newData.DonateurCu=Patrenaire.cuDonateur
                            newData.userCu=5
                            newData.ladate=moment(newData.ladate).format('YYYY-MM-DD');
                            console.log("newData ToAdd ",newData)
                            AddMutation.mutateAsync(newData)
                            resolve();
                          }), 
                        onRowUpdate: (newData, oldData) =>
                          new Promise((resolve, reject) => {
                            //const index = oldData.tableData.id;
                            console.log("newData ToUpdate ",newData)
                            newData.ladate=moment(newData.ladate).format('YYYY-MM-DD');
                            //UpdateDetailMutation.mutate(newData)
                            delete newData.TypedeDon
                            delete newData.TypedeValeur
                            delete newData.nomDonateur
                            delete newData.QuiReception

                            UpdateMutation.mutateAsync(newData)
                            resolve();
                          }),
                         onRowDelete: oldData =>
                          new Promise((resolve, reject) => {
                            DeleteMutationRow.mutateAsync(oldData)
                            resolve();
                          }) 
                      }}
                    components={{
                        Row: props => (
                          <MTableBodyRow
                            {...props}
                            onDoubleClick={e => {
                              console.log(e);
                            }}
                          />
                        ),
                        Toolbar: (props) => (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              backgroundColor: '#e8eaf5'
                            }}
                          >
                            <MTableToolbar {...props} />
                          </div>
                        )
                      }}
                    localization={{
                        body: {
                            emptyDataSourceMessage: "Pas d'enregistreent à afficher",
                            addTooltip: 'Ajouter',
                            deleteTooltip: 'Supprimer',
                            editTooltip: 'Editer',
                            filterRow: {
                                filterTooltip: 'Filtrer'
                            },
                            editRow: {
                                deleteText: 'Voulez-vous supprimer cette ligne?',
                                cancelTooltip: 'Annuler',
                                saveTooltip: 'Enregistrer'
                            }
                        },
                        grouping: {
                            placeholder: "Tirer l'entête ...",
                            groupedBy: 'Grouper par:'
                        },
                        header: {
                            actions: 'Actions'
                        },
                        pagination: {
                            labelDisplayedRows: '{from}-{to} de {count}',
                            labelRowsSelect: 'lignes',
                            labelRowsPerPage: 'lignes par page:',
                            firstAriaLabel: 'Première page',
                            firstTooltip: 'Première page',
                            previousAriaLabel: 'Page précédente',
                            previousTooltip: 'Page précédente',
                            nextAriaLabel: 'Page suivante',
                            nextTooltip: 'Page suivante',
                            lastAriaLabel: 'Dernière page',
                            lastTooltip: 'Dernière page'
                        },
                        toolbar: {
                            addRemoveColumns: 'Ajouter ou supprimer des colonnes',
                            nRowsSelected: '{0} ligne(s) sélectionée(s)',
                            showColumnsTitle: 'Voir les colonnes',
                            showColumnsAriaLabel: 'Voir les colonnes',
                            exportTitle: 'Exporter',
                            exportAriaLabel: 'Exporter',
                            exportName: 'Exporter en CSV',
                            searchTooltip: 'Chercher',
                            searchPlaceholder: 'Chercher'
                        }
                    }}
                    />}
            </Grid>
        </Grid>
    {/*<Grid item xs={1}></Grid>  Dernière colonne vide */}
</div>
  )
  
};

export default DonsMainForm;

