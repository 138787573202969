
import { clientHTTP } from './clientHTTP';
export const GetAllDons= async ({queryKey }) => {
    const [,LeDonateur]= queryKey;
        console.log("GetAllDons cuDonnateur=",LeDonateur)    
        const {data}= await clientHTTP.post(`/api/dns/all`,LeDonateur)
        console.log('DbServices => GetAllDons => data', data)
      return data;
    };

export const TypeDeDon= async () => {
    const {data}= await clientHTTP.post(`/api/dns/typed`)
    console.log('DbServices => TypeDeDon => data', data)
    return data;
};
export const TypeDeVal= async () => {
    const {data}= await clientHTTP.post(`/api/dns/typev`)
    console.log('DbServices => TypeDeDon => data', data)
    return data;
};
export const AddDon = async (rowData) => {
    console.log('DbServices => AddDon => rowData', rowData)
    const {data}= await clientHTTP.post(`/api/dns/add`,rowData)
      console.log('DbServices => AddDon => data', data)
      return data;
}
export const DelDon =async (rowData) => {
    console.log('DbServices => DelDon => rowData', rowData)
    const {data}= await clientHTTP.post(`/api/dns/del`,rowData)
    console.log('DbServices => DelDon => data', data)
    return data;
}
export const EditDon =async (rowData) => {
    console.log('DbServices => EditDon => rowData', rowData)
    const {data}= await clientHTTP.post(`/api/dns/edit`,rowData)
    console.log('DbServices => EditDon => data', data)
    return data;
}