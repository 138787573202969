export function tempo(duration=1000){
    return new Promise((resolve )=> {
      window.setTimeout(resolve,duration)
    })
    }
    export function gestErreurReseau (error) {
        var retour={};
        console.log('Erreur est survenue : ',error)
        if (error.response) {
          // Request made and server responded
          retour.data=error.response.data;
          console.log(error.response.data);
          retour.status=error.response.status;
          console.log(error.response.status);
          retour.headers=error.response.headers;
          console.log(error.response.headers);
          return retour
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
          retour.request=error.request;
          return retour
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
          retour.message=error.message
        }
    
      }
    
     export function stringToDate(_date,_format,_delimiter)
      {
                  var formatLowerCase=_format.toLowerCase();
                  var formatItems=formatLowerCase.split(_delimiter);
                  var dateItems=_date.split(_delimiter);
                  var monthIndex=formatItems.indexOf("mm");
                  var dayIndex=formatItems.indexOf("dd");
                  var yearIndex=formatItems.indexOf("yyyy");
                  var month=parseInt(dateItems[monthIndex]);
                  month-=1;
                  var formatedDate = new Date(dateItems[yearIndex],month,dateItems[dayIndex]);
                  return formatedDate;
      }