import React from "react";
import { Route,Routes } from "react-router-dom";
//import MainScreen from "../MainScreen";
import * as urls from "./urls";
import ConnectUserForm from "./ConnectUserForm";
import CreateUserForm from "./CreateUserForm";
import DonsMainForm from "../DbForms/FormDons/DonsMainForm";
import DonateurMainForm from "../DbForms/FormDonateur/DonateurMainForm";
import DonateurEditForm from "../DbForms/FormDonateur/DonateurEditForm";
//import StartApp from './StartApp';
/*import SecteursMainForm from "../DbForms/FormSecteurs/SecteursMainForm";
import ActiviesMainForm from "../DbForms/FormActivites/ActiviesMainForm";
import IndicateursMainForm from "../DbForms/FormIndicateurs/IndicateursMainForm";*/
const MainRouter = () => {

    return (
    
        <Routes>
            <Route exact path={urls.parts} element={<DonateurMainForm  />} />
            <Route exact path={urls.HOME} element={<DonateurMainForm  />} />
            <Route exact path={urls.LOGOUT} element={<ConnectUserForm />} />
            {/* <Route path={urls.Confirm} element={<ConfirmMailPage />} /> */}
           {/*  <Route path={urls.mainScreen} element={<MainScreen />} /> */}
            <Route path={urls.NewUserForm} element={<CreateUserForm />} />
            <Route path={urls.dons} element={<DonsMainForm />} />
            <Route path={urls.edpart} element={<DonateurEditForm />} />
           {/*  <Route path={urls.MenuSecteurs} element={<SecteursMainForm />} />
            <Route path={urls.MenuActivites} element={<ActiviesMainForm />} />
            <Route path={urls.MenuIndicateur} element={<IndicateursMainForm />} /> */}
        </Routes>
    
)};

export default MainRouter;
