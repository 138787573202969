import React, { useState, forwardRef ,useEffect} from 'react';
import MaterialTable, { MTableBodyRow ,MTableToolbar} from "material-table";
//import MaterialTable, { MTableBodyRow ,MTableToolbar}  from '@material-table/core';
import { Grid } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import AddBox from '@mui/icons-material/AddBox';
import Check from '@mui/icons-material/Check';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Clear from '@mui/icons-material/Clear';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DeleteIcon from "@mui/icons-material/Delete";
import Edit from '@mui/icons-material/Edit';
import FilterList from '@mui/icons-material/FilterList';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';
import Remove from '@mui/icons-material/Remove';
import SaveAlt from '@mui/icons-material/SaveAlt';
import Search from '@mui/icons-material/Search';
import ViewColumn from '@mui/icons-material/ViewColumn';
import { useQuery,useMutation ,useQueryClient } from "react-query";
import Confirm from '../../share/ConfirmService/Confirm';
import { getUserInfoByToken } from '../../../DbServices/users.dbservices';
import * as DonateurService from '../../../DbServices/donateur.dbServices';
import * as DonsDbService from '../../../DbServices/dons.dbServices';
import { GetListSimpleUser } from '../../../DbServices/users.dbservices';
import { GetAllSecteurs } from '../../../DbServices/secteur.dbServices';
import DetailsPartenaire from './DetailsPartenaire';
import './donateur.css'
import { useNavigate } from 'react-router-dom';
import * as urls from '../../share/urls'
const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownwardIcon {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };
  

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const DonateurMainForm = () => {
    const [Message, setMessage]=useState("");
    const [TheUser,setTheUser]=useState({})
    const [ShowDialogMessageBox,setShowDialogMessageBox]=useState(false);
    const TheToken=localStorage.getItem("partgifts")
    console.log("TheToken ", TheToken)
    const queryClient = useQueryClient()
    //console.log("IndicateursMainForm State ", state)
  // eslint-disable-next-line
    const dataSecteurs =useQuery("Secteurs", GetAllSecteurs,
    {
      staleTime: 60 * 1000, 
      cacheTime: 60 * 1000
    });
    // eslint-disable-next-line
    const dataTypesDons =useQuery("TypeDons", DonsDbService.TypeDeDon,
    {
      staleTime: 60 * 1000, 
      cacheTime: 60 * 1000
    });
    // eslint-disable-next-line
   const dataTypesVal =useQuery("TypeVal", DonsDbService.TypeDeVal,
    {
      staleTime: 60 * 1000, 
      cacheTime: 60 * 1000
    });
    const dataUsersList =useQuery("LesUsers", GetListSimpleUser,
    {
      staleTime: 60 * 1000, 
      cacheTime: 60 * 1000
    });
   console.log("dataUsersList ",dataUsersList)
    const navigate = useNavigate();
    if (!TheToken) navigate(urls.LOGOUT);
    useEffect(() => 
    {
      getUserInfoByToken({"token":TheToken}).then(resp=> {
        console.log('--------DonateurMainForm =>getUserInfoByToken => resp',resp)
        setTheUser(resp)
      })
      },[TheToken]) 
      console.log('--------DonateurMainForm =>getUserInfoByToken => TheUser',TheUser)
      const columns=[
        {title: "cuDonateur", field: "cuDonateur", hidden: true},
        {title: "typeDonateur", field: "typeDonateur", hidden: true},
        {title: "Type de Partenaire", field: "libTypesDonateur"},
        {title: "Nom", field: "nomDonateur"},
        {title: "Date d'anniversaire / création", field: "dateNaissance"},
        {title: "Localité", field: "localiter"},
        {title: "Adresse", field: "adresseDonateur"},
        {title: "Email", field: "emailDonateur"},
        {title: "Téléphone", field: "telephoneDonateur"},
        {title: "Secteurcu", field: "secteurDonateur",hidden:true},
        {title: "Secteur", field: "nomSecteur"},
        {title: "Publier", field: "publierDonateur"},
        {title: "userCu", field: "userCu", hidden: true},
        ]
      const Donateurs = DonateurService.DonnateursRQ()
             
      console.log("Donateurs ",Donateurs)
      
          
      const DeleteMutationRow = useMutation(
        (dataToDelate) =>DonateurService.DelDonateur(dataToDelate),
        {
          onSuccess: () => {
            queryClient.invalidateQueries("Partenaires");
          },
        }
      );
   

  const OnAddNewRow =async (rowData)=>{
        console.log("TheUser  ",TheUser)
        rowData.userCu=TheUser.cuUser
        //{ typeDonateur: 1, nomDonateur: "aaaa", adresseDonateur: "dddd", telephoneDonateur: "ddd", publierDonateur: "Non", dateNaissance: "2022-02-03", lieuResidence: "dddd", userCu: undefined }
        //setOperator("Add")
        let Param={}
        Param.Oper="Add"
        Param.RowData=rowData
        Param.TheUser=TheUser
        navigate(urls.edpart,{state:Param});
        /* SetTheRowData(rowData)
        setTitreBox('Ajouter un partenaire')
        ChangeModalDialogEtat(true) */

    }
  const OnEditRow =(rowData)=>{
    console.log("rowData.userCu",rowData.userCu)
    console.log("TheUser.cuUser",TheUser.cuUser)
    if (rowData.userCu!==TheUser.cuUser)
    {
      setMessage("Vous ne pouvez pas Modifier cet enregistrement pour ne l'avoir pas saisi")
      setShowDialogMessageBox(true)
    }  
    else 
        {
         // setOperator("Edit")
          //delete rowData.tableData
          console.log("OnEditRow ",rowData)
          /* SetTheRowData(rowData)
          setTitreBox('Modification')
          ChangeModalDialogEtat(true) */
          let Param={}
          Param.Oper="Edit"
          Param.RowData=rowData
          Param.TheUser=TheUser
          navigate(urls.edpart,{state:Param});
        }
    }
    
    const OnDelete=async (rowData)=>{
      // eslint-disable-next-line
      if (rowData.userCu!=TheUser.cuUser)
      {
        setMessage("Vous ne pouvez pas supprimer cet enregistrement pour ne l'avoir pas saisi")
        setShowDialogMessageBox(true)

      } else
         {const result = await Confirm.show({
           title:"ATTENTION Veillez confirmer",
           message: 'Voulez-vous vraiment supprimer cet élément ?'
         });
         console.log(rowData)
         if (result) {
           DeleteMutationRow.mutate(rowData)
         }
        }
     }
     return (
        <div>
           <Snackbar open={ShowDialogMessageBox} autoHideDuration={6000} onClose={() => setShowDialogMessageBox(false)}>
              <Alert onClose={() => setShowDialogMessageBox(false)} severity="error" sx={{ width: '100%' }}>
              {Message}
              </Alert>
           </Snackbar>
          
          
          
          {/* <Snackbar
            open={ShowDialogMessageBox}
            autoHideDuration={6000}
            onClose={() => setShowDialogMessageBox(false)}
            message={Message}
            action={action}
          /> */}
          <Grid container spacing={1} direction="column" justifyContent="center" alignItems="flex-start">
            <Grid  container direction="column" justifyContent="center" alignItems="center">
               <h2 className='titreColor'> Fichier / Table des partenaires </h2>
            </Grid>
          
             <div>
            {Donateurs.status === "error" && <div>Une Erreur s'est produite: {Donateurs.error}</div>}
            {Donateurs.status === "loading" &&  <div> <h2>Chargement en cours...</h2> </div>}
            {Donateurs.status === "success" && 
                <MaterialTable
                    title=  {""}
                    columns={columns}
                    data={Donateurs.data}
                    icons={tableIcons}
                    onRowClick={(async (evt, selectedRow) => { 
                      console.log("selectedRow ",selectedRow)                 
                      })
                      }
                    options={{
                        exportButton: true,
                        pageSize: 5,
                        pageSizeOptions: [5, 10],
                        headerStyle: {
                          color: "#fff",
                          fontWeight: "700 !important",
                          background: "#800b00d2",
                      }
                      }}
                    actions={[
                        {
                            icon: () => <DeleteIcon/>,
                            tooltip: 'Supprimer',
                            onClick: (event, rowData) => OnDelete(rowData)
                          },
                          {
                            icon: () => <AddBox/>,
                            tooltip: 'Ajouter ',
                            isFreeAction: true,
                            onClick: (event, rowData) => {
                              console.log("OnAddNewRow ",rowData)
                              OnAddNewRow(rowData)
                            }
                          },
                          {
                            icon: () => <Edit/>,
                            tooltip: 'Modifier',
                            onClick: (event, rowData) =>
                            {
                              console.log("OnEditRow ",rowData)

                              OnEditRow(rowData)
                            } 
                          },
                          {
                            icon: () => <MonetizationOnIcon/>,
                            tooltip: 'Dons',
                            onClick: (event, rowData) =>
                            {
                              console.log("AddGift cuDonateur",rowData.cuDonateur)
                              navigate(urls.dons,{state:rowData});
                            
                            } 
                          },
                    ]}
                    components={{
                        Row: props => (
                          <MTableBodyRow
                            {...props}
                            onDoubleClick={e => {
                              console.log(e);
                            }}
                          />
                        ),
                        Toolbar: (props) => (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "left",
                              backgroundColor: '#e8eaf5'
                            }}
                          >
                            <MTableToolbar {...props} />
                          </div>
                        )
                      }}
                      detailPanel={(rowData) => {
                        console.log(" Détail Panel Activé rowData ", rowData)
                        return( <div><DetailsPartenaire Partenaire={rowData}/></div>)
                      }}  
                    localization={{
                        body: {
                            emptyDataSourceMessage: "Pas d'enregistreent à afficher",
                            addTooltip: 'Ajouter',
                            deleteTooltip: 'Supprimer',
                            editTooltip: 'Editer',
                            filterRow: {
                                filterTooltip: 'Filtrer'
                            },
                            editRow: {
                                deleteText: 'Voulez-vous supprimer cette ligne?',
                                cancelTooltip: 'Annuler',
                                saveTooltip: 'Enregistrer'
                            }
                        },
                        grouping: {
                            placeholder: "Tirer l'entête ...",
                            groupedBy: 'Grouper par:'
                        },
                        header: {
                            actions: 'Actions'
                        },
                        pagination: {
                            labelDisplayedRows: '{from}-{to} de {count}',
                            labelRowsSelect: 'lignes',
                            labelRowsPerPage: 'lignes par page:',
                            firstAriaLabel: 'Première page',
                            firstTooltip: 'Première page',
                            previousAriaLabel: 'Page précédente',
                            previousTooltip: 'Page précédente',
                            nextAriaLabel: 'Page suivante',
                            nextTooltip: 'Page suivante',
                            lastAriaLabel: 'Dernière page',
                            lastTooltip: 'Dernière page'
                        },
                        toolbar: {
                            addRemoveColumns: 'Ajouter ou supprimer des colonnes',
                            nRowsSelected: '{0} ligne(s) sélectionée(s)',
                            showColumnsTitle: 'Voir les colonnes',
                            showColumnsAriaLabel: 'Voir les colonnes',
                            exportTitle: 'Exporter',
                            exportAriaLabel: 'Exporter',
                            exportName: 'Exporter en CSV',
                            searchTooltip: 'Chercher',
                            searchPlaceholder: 'Chercher'
                        }
                    }}
                    />
                }             
            </div>
            <Grid container spacing={1} direction="column" justifyContent="center" alignItems="center">
              
             {/*  <DialogMessageBox open={ShowDialogMessageBox} setOpen={setShowDialogMessageBox} Message={Message} /> */} 
            </Grid> 
            <Grid container spacing={1} direction="column" justifyContent="center" alignItems="center">
              {/*  {data && 
              */}
            </Grid> 
          </Grid>
        </div>
    )
}

export default DonateurMainForm
