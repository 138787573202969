import React ,{useRef,useState } from 'react'
import { useForm, Controller } from 'react-hook-form';
import { Button,Grid} from "@material-ui/core";
import { InputAdornment, IconButton, TextField} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { TestUserNameExist,SeConnecter} from '../../DbServices/users.dbservices';
import * as urls from "./urls";
const ConnectUserForm = () => {
    const navigate = useNavigate();
    const MessageInvalideMail=useRef("") 
    const TheToken=localStorage.getItem("partgifts") 
    const [showPassword, setShowPassword] = useState(false);
    const [TheMail, setTheMail] = useState('');
    const isValidEmail = email =>
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );
    const handleClick = () => setShowPassword(prev => !prev);
      

    const onSubmit=async data => {
       console.log("On SUBMIT",data)
       var re=await SeConnecter(data)
       console.log(" On SUBMIT ---> retour SECONNECTER",re)
       if (re.token.length>0) {
        localStorage.setItem("partgifts",re.token)
           navigate(urls.parts);
        } 
    }
   
    const { handleSubmit,reset, formState: { errors },control,   } = useForm({mode:'onTouched'});
    
    const TestValidMail= async email =>{
        var resultat=true
        MessageInvalideMail.current=""
        setTheMail(email)
        var IsValid=isValidEmail(email)
        if (!IsValid) {
            MessageInvalideMail.current="Le format du email n'est pas valide"
            resultat =false
        } else {
            MessageInvalideMail.current=""
            var existe = await TestUserNameExist(email)
            console.log("existe ?",existe)
            if (!existe) {
                MessageInvalideMail.current="Aucun utilisateur n'a ce mail."
                resultat=false
            }
        }
        console.log("TestValidMail ",resultat)
        return resultat
    }

    
    if (TheToken)  navigate(urls.parts);

    return (
        <div>
         {!TheToken &&
          <form onSubmit={handleSubmit(onSubmit)}> 
            <Grid  container  direction="column" justifyContent="center" alignItems="center">
            <Grid >   
                <h3 style={{ color: "#90050E" }} >Connection à la plateforme</h3>

                <Grid  container item xs={12}  direction="column" justifyContent="center" alignItems="flex-start">
                <Grid >
                        <Controller
                        name="email"
                        control={control}
                        defaultValue={""}
                        rules={{ 
                            required: 'Ce champ doit avoir une valeur' ,
                            validate:async (value)=>{
                                var IsValid=isValidEmail(value)
                                //var existe = await TestUserNameExist(value)
                                const retour =await TestValidMail(value)
                               // existe=!existe
                            console.log("retour",retour)
                            console.log("IsValid",IsValid)

                            return retour 

                            }
                        }}
                        render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error },
                            formState,
                        }) =>
                        (
                            <TextField
                            label={ <span style={{ color: "#0D2801" }}> Email </span>}
                            onChange={onChange} value={value}
                            onBlur={onBlur}
                            fullWidth
                            
                            />
                        )}
                    />
                
                <Grid >
                    {errors.email && <small className="text-danger">{errors.email.message}</small>} 
                    {errors.email && errors.email.type === "validate" && (
                        <small className="text-danger">{MessageInvalideMail.current}</small>
                    )
                    }
                </Grid>
                </Grid >
                </Grid> 
                 {/* Mot de passe */}
                 <Grid container  item xs={12} direction="column" justifyContent="flex-start" alignItems="flex-start">
                <Grid >
                <Controller
                    name="password"
                    control={control}
                    defaultValue={""}
                    rules={{ 
                        required: 'Ce champ doit avoir une valeur',
                        validate:async (value)=>{
                        let data = {email:TheMail, password:value}
                        console.log("TheMail;",TheMail)
                        var re=await SeConnecter(data)
                        if (re.token.length>0) return true
                             else return false
                        }
                    }}
                    render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState,
                    }) =>
                    (
                        <TextField
                        label={ <span style={{ color: "#0D2801" }}>Mot de passe </span>}
                        onChange={onChange} value={value}
                        type={showPassword ? 'text' : "password"}
                        fullWidth
                        InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                 <IconButton
                                   onClick={handleClick}
                                   edge="end"
                                 >
                                   {showPassword ? <Visibility /> : <VisibilityOff />}
                                 </IconButton>
                               </InputAdornment>
                            ) 
                          }}
                        />
                    )}
                />
                 <Grid >  
                    {errors.password && <small className="text-danger">{errors.password.message}</small>} 
                    {errors.password && errors.password.type === "validate" && (
                            <div className="text-danger">Mot de passe erroné</div>
                        )
                        }
                </Grid>
                </Grid >
                </Grid> 
                {/* FIN MOT DE PASSE */}
                
                <Grid container direction="row" justifyContent="center" alignItems="center">
                    <Grid item xs={5} >
                    <Button variant="contained" color="secondary" size="small" onClick={() =>
                        {
                            reset({email:'',password:''})
                        }}>  Abandonner 
                    </Button>
                    </Grid>
                    <Grid item xs={5} >
                        <Button variant="contained" size="small"  color="primary" type="submit"> Connecter</Button>
                    </Grid>
                </Grid>
                
                {/*  <Grid container direction="row" justifyContent="center" alignItems="center">
                <Button variant="contained" color="inherit" size="small" color="success"
                    startIcon={<AddReactionSharpIcon/>}
                    onClick={() =>
                    {   console.log("ConnectUser => OnClik Ajouter User ")
                        const OnUser={ "degre":2}
                        navigate(urls.NewUserForm, {state:OnUser });
                    }}>  Ajouter un utilisateur
                </Button>
                </Grid>  */}

                </Grid>    
            </Grid>   
          </form>}

        </div>
    )
}
export default ConnectUserForm
