import React from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import './donateur.css'
const theme = createTheme();

theme.typography.h6 = {
  fontSize: '1.2rem',
  '@media (min-width:600px)': {
    fontSize: '0.5rem',
  },
  [theme.breakpoints.up('xs')]: {
    fontSize: '1rem',
  },
};
const DetailsPartenaire = ({Partenaire}) => {
  return (
    <div>
        <Grid container direction="column" justifyContent="center" alignItems="center">
             <ThemeProvider theme={theme}>
                <Typography variant="h6" className='titreLabel'> <strong>Autres informations sur le Partenaire</strong></Typography>
            </ThemeProvider>
            
            <Grid container direction="row" justifyContent="space-evenly" alignItems="center" >  
            <ThemeProvider theme={theme}>
                <Typography variant="h6">Saisi par :{Partenaire.SaisiPar}</Typography>
            </ThemeProvider>
            </Grid>
        </Grid>
    </div>
  )
}

export default DetailsPartenaire