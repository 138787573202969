import React ,{useRef,useState,useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form';
import { Button,Grid} from "@material-ui/core";
import {InputAdornment, IconButton, TextField} from '@material-ui/core';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import * as UserDbServices from '../../DbServices/users.dbservices';
import { useNavigate,useLocation } from 'react-router-dom';
import * as urls from './urls'


const CreateUserForm = () => {
    const navigate = useNavigate();
    const {state}=useLocation()
    console.log("In CreateUserForm")
    var TypeUserListe=[]
    TypeUserListe = [
        { value: 1, text: "CP-SE-DP" },
        { value: 2, text: "Animateur" },
        { value: 3, text: "Bénévole" },
    ];
    switch (state.degre) {
        case 0 : 
            TypeUserListe = [
                { value: 1, text: "CP-SE-DP" },
                { value: 2, text: "Animateur" },
                { value: 3, text: "Bénévole" },
            ];
            break;
        case 1 : 
            TypeUserListe = [
                { value: 2, text: "Animateur" },
                { value: 3, text: "Bénévole" },
            ];
            break;
        case 2 : 
            TypeUserListe = [
                { value: 2, text: "Animateur" },
                { value: 3, text: "Bénévole" },
            ];
            break;
        default:
            TypeUserListe = [
                { value: 3, text: "Bénévole" },
            ];

    }
    const MessageInvalideMail=useRef("") 
    const [showPassword, setShowPassword] = useState(false);
    console.log("TypeUserListee  ",TypeUserListe)
    const isValidEmail = email =>
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );
    const handleClick = () => {
        setShowPassword(prev => !prev);
      }
    useEffect(() => {
       console.log("useEffect CreateUserForm")
    }, [])

const onSubmit=async data => {
       var SiteAdresse=window.location.protocol+'//'+window.location.host+'/'
       data.SiteAdresse=SiteAdresse
       console.log("On SUBMIT",data)
       const resp= await UserDbServices.InsertUser(data)
       console.log("protocol41 ", resp.protocol41)
       navigate(urls.HOME);
      }

   
    const { handleSubmit,reset,formState: { errors },control,   } = useForm({mode:'onTouched'});
    
    const TestValidMail= async email =>{
        var resultat=true
        MessageInvalideMail.current=""
        var IsValid=isValidEmail(email)
        if (!IsValid) {
            MessageInvalideMail.current="Le format du email n'est pas valide"
            resultat =false
        } else {
            var existe = await UserDbServices.TestUserNameExist(email)
            if (existe) {
                MessageInvalideMail.current="Ce mail est déja utilisé. Veuillez en saisir un autre"
                resultat=false
            }

        }
        console.log("TestValidMail ",resultat)
        return resultat
    }

 


    return (
        <div>
         
          <form onSubmit={handleSubmit(onSubmit)}> 
            <Grid  container  direction="column" justifyContent="center" alignItems="center">
            <Grid >   
                <h3 style={{ color: "#90050E" }} >Création d'un nouvel utilisateur</h3>
                <Grid  container item xs={12}  direction="column" justifyContent="center" alignItems="flex-start">
                <Grid >
                        <Controller
                        name="email"
                        control={control}
                        defaultValue={""}
                        rules={{ 
                            required: 'Ce champ doit avoir une valeur' ,
                            validate:async (value)=>{
                                var IsValid=isValidEmail(value)
                                //var existe = await TestUserNameExist(value)
                                const retour =await TestValidMail(value)
                               // existe=!existe
                            console.log("retour",retour)
                            console.log("IsValid",IsValid)

                            return retour 

                            }
                        }}
                        render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error },
                            formState,
                        }) =>
                        (
                            <TextField
                            label={ <span style={{ color: "#0D2801" }}> Email </span>}
                            onChange={onChange} value={value}
                            onBlur={onBlur}
                            fullWidth
                            
                            />
                        )}
                    />
                
                <Grid >
                    {errors.email && <small className="text-danger">{errors.email.message}</small>} 
                    {errors.email && errors.email.type === "validate" && (
                        <small className="text-danger">{MessageInvalideMail.current}</small>
                    )
                    }
                </Grid>
                </Grid >
                </Grid> 
                 {/* Mot de passe */}
                 <Grid container  item xs={12} direction="column" justifyContent="flex-start" alignItems="flex-start">
                <Grid >
                <Controller
                    name="password"
                    control={control}
                    defaultValue={""}
                    rules={{ 
                        required: 'Ce champ doit avoir une valeur',
                        
                    }}
                    render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState,
                    }) =>
                    (
                        <TextField
                        label={ <span style={{ color: "#0D2801" }}>Mot de passe </span>}
                        onChange={onChange} value={value}
                        type={showPassword ? 'text' : "password"}
                        fullWidth
                        InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                 <IconButton
                                   onClick={handleClick}
                                   edge="end"
                                 >
                                   {showPassword ? <Visibility /> : <VisibilityOff />}
                                 </IconButton>
                               </InputAdornment>
                            ) 
                          }}
                        />
                    )}
                />
                 <Grid >  
                    {errors.password && <small className="text-danger">{errors.password.message}</small>} 
                    {errors.password && errors.password.type === "validate" && (
                            <div className="text-danger">Mot de passe erroné</div>
                        )
                        }
                </Grid>
                </Grid >
                </Grid> 
                {/* FIN MOT DE PASSE */}
                 {/* Nom        */}
                 <Grid  container item xs={12}  direction="column" justifyContent="center" alignItems="flex-start">
                <Grid >
                        <Controller
                        name="lastname"
                        control={control}
                        defaultValue={""}
                        rules={{ 
                            required: 'Ce champ doit avoir une valeur' ,
                    
                        }}
                        render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error },
                            formState,
                        }) =>
                        (
                            <TextField
                            label={ <span style={{ color: "#0D2801" }}> Nom </span>}
                            onChange={onChange} value={value}
                            onBlur={onBlur}
                            fullWidth
                            
                            />
                        )}
                    />
                
                <Grid >
                    {errors.lastname && <small className="text-danger">{errors.lastname.message}</small>} 
                    
                </Grid>
                </Grid >
                </Grid> 
                {/* FIN NOM    */}
                {/* Prénom        */}
                 <Grid  container item xs={12}  direction="column" justifyContent="center" alignItems="flex-start">
                <Grid >
                        <Controller
                        name="firstname"
                        control={control}
                        defaultValue={""}
                        rules={{ 
                            required: 'Ce champ doit avoir une valeur' ,
                    
                        }}
                        render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error },
                            formState,
                        }) =>
                        (
                            <TextField
                            label={ <span style={{ color: "#0D2801" }}> Prénom </span>}
                            onChange={onChange} value={value}
                            onBlur={onBlur}
                            fullWidth
                            
                            />
                        )}
                    />
                
                <Grid >
                    {errors.firstname && <small className="text-danger">{errors.firstname.message}</small>} 
                    
                </Grid>
                </Grid >
                </Grid> 
                {/* FIN PRENOM   */}
               
                   
                <Grid container direction="row" justifyContent="center" alignItems="center">
                    <Grid item xs={5} >
                    <Button variant="contained" color="secondary" size="small" onClick={() =>
                        {
                            reset({email:'',password:'',firstname:'',lastname:'',communeCu:0})
                        }}>  Abandonner 
                    </Button>
                    </Grid>
                    <Grid item xs={5} >
                        <Button variant="contained" size="small"  color="primary" type="submit"> Continuer</Button>
                    </Grid>
                </Grid>
                <Grid container direction="row" justifyContent="center" alignItems="center">
                <Button variant="contained" color="default" size="small" onClick={() =>
                    {
                        navigate(urls.mainScreen);
                    }}>  Page principale
                </Button>
                </Grid>
                </Grid>    
            </Grid>   
          </form>

        </div>
    )
}

export default CreateUserForm
