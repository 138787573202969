import React ,{useEffect,useState} from 'react'
import { useForm, Controller } from 'react-hook-form';
import { Button,Grid} from "@material-ui/core";
import { MenuItem,TextField} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import { useQuery,useMutation ,useQueryClient  } from "react-query";
import { useNavigate,useLocation } from 'react-router-dom';
import * as DonateurService from '../../../DbServices/donateur.dbServices';
import {stringToDate} from '../../share/utilsFunction';
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import './donateur.css'
const DonateurEditForm = () => {
  const { handleSubmit,reset, getValues,formState: { errors,isDirty,isSubmitSuccessful  },control,   } = useForm({mode:'onTouched'});
  const {state}=useLocation()
  const navigate = useNavigate();
  const {TheUser,RowData,Oper}=state
  const queryClient = useQueryClient()
  const dataSecteurs =queryClient.getQueryData("Secteurs")  
  console.log("DonateurEditForm=> RowData received ",RowData)
  console.log("DonateurEditForm=> oper ",Oper)
  
  // eslint-disable-next-line
  let isPhisique =RowData.typeDonateur==1 
  const [Physique,setPhysique]=useState(isPhisique)
  const Publier=[
    {"key":1,"id": "Non","Text":"Non"},
    {"key":2,"id": "Oui","Text":"Oui"}
  ]
  const UpdateMutation = useMutation((dataToUpdate) => 
      DonateurService.EditDonateur(dataToUpdate),
          {
            onSuccess: () => queryClient.invalidateQueries("Partenaires"),
          }
        );
const AddMutation =  useMutation( newRecord => 
DonateurService.AddDonateur(newRecord), 
    {
        onSuccess: () => queryClient.invalidateQueries("Partenaires"),
    })
  const onSubmit=data => {
    data.dateNaissance=moment(data.dateNaissance).format('YYYY-MM-DD');
    data.userCu=TheUser.cuUser
      console.log("DonateurEditForm => onSubmit =>data",data, Oper)
      // eslint-disable-next-line
      if (Oper=="Add") 
          AddMutation.mutate(data)
      else 
      // eslint-disable-next-line
        if (Oper=="Edit") 
            {
              const newData={...RowData,...data}
              delete newData.libTypesDonateur
              delete newData.nomSecteur
              delete newData.SaisiPar
              UpdateMutation.mutate (newData)
            }
     //setOpen(false)
    }
  const  {data, status }= useQuery("TypesDonateurs", DonateurService.TypeDonateur,
              { staleTime: 60 * 1000, cacheTime: 60 * 1000 }
      );
  
  useEffect(() => {
    if (isSubmitSuccessful) {
        reset(getValues())
        }
    }, [isSubmitSuccessful, isDirty])     
 return ( 
        <div>
            <Grid  container  direction="column" justifyContent="center" alignItems="center">
            <Grid container  direction="row" justifyContent="flex-end" alignItems="flex-end">
                <div>
                    
                    <Button variant="contained" size="small" color="primary" disabled={isDirty}
                    onClick={() => {
                            navigate(-1)
                    } }
                    > 
                    Fermer
                    </Button>
                </div>
            </Grid>
            <Grid container  direction="row" justifyContent="flex-end" alignItems="flex-end">
                 {isDirty ? <h3 className="text-danger"> Modifications en cours. Pensez à enregistrer</h3>:""}
            </Grid>
            <form onSubmit={handleSubmit(onSubmit)}> 
{/*typeDonateur*/}<Grid item xs={12}>  
                   <Controller
                        name="typeDonateur"  
                        control={control}
                        //defaultValue={RowData.ligne_id}
                        rules={{ required: "Veuillez choisir un le type de partenaire" }}
                        defaultValue={RowData.typeDonateur}
                        render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error },
                            formState,
                        }) =>(
                            <TextField
                            select
                            label={ <span className='titreLabel'> Type de partenaire </span>}
                            value={value}
                            onChange={onChange}
                            fullWidth>
                                {status === "success" && data.map((ress) => (
                                <MenuItem 
                                   key={ress.cuTypesDonateur} value={ress.cuTypesDonateur}
                                   onClick= {async(event) => {
                                    const { value } = event.currentTarget.dataset;
                                    console.log(" value ", value)
                                    // eslint-disable-next-line
                                    if (value=="1")
                                    setPhysique(true) 
                                      else setPhysique(false)
                                }} >
                                        {ress.libTypesDonateur}
                                </MenuItem>
                                ))
                                }        
                            </TextField>
                            )
                        }
                        />
                      <Grid >
                      {errors.typeDonateur && <small className="text-danger">{errors.typeDonateur.message}</small>}           
                      </Grid>  
{/*FIN typeDonateur*/} </Grid> 
{/*NonDonateur*/}<Grid  container item xs={12}  direction="column" justifyContent="center" alignItems="flex-start">
                  <Controller
                                name="nomDonateur"
                                control={control} variant="standard"
                                defaultValue={RowData.nomDonateur}
                                rules={{ 
                                    required: 'Ce champ doit avoir une valeur' ,
                                }}
                                render={({
                                    field: { onChange, onBlur, value, name, ref },
                                    fieldState: { invalid, isTouched, isDirty, error },
                                    formState,
                                }) =>
                                (
                                <TextField
                                label={ <span className='titreLabel'> Nom du partenaire </span>}
                                onChange={onChange} value={value}
                                onBlur={onBlur} variant="standard"
                                fullWidth/>
                                )}
                            />
                        <Grid >
                            {errors.nomDonateur && <small className="text-danger">{errors.nomDonateur.message}</small>} 
                        </Grid>
{/* FIN NON DONATEUR*/}</Grid>  
                        {/* Date NAISSANCE    */}
                        {(() => {return <p>{Physique}</p>} 
                        )}
                        {Physique &&<Grid  container item xs={9}  direction="column" justifyContent="center" alignItems="center">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <>
                        <Controller
                          name="dateNaissance"
                          control={control}
                          
                          defaultValue={stringToDate(moment(RowData.dateNaissance).format('DD/MM/yyyy'),"dd/MM/yyyy","/")}
                          render={({ field : {onChange , value } }) => (
                            <KeyboardDatePicker
                              onChange={onChange}
                              format="dd/MM/yyyy"
                              value={value}
                              label={<span className='titreLabel'>Date Anniversaire</span>}
                              fullWidth
                            />
                          )}
                        />
                        </>
                      </MuiPickersUtilsProvider>
                            <Grid >
                                {errors.dateNaissance && <small className="text-danger">{errors.dateNaissance.message}</small>} 
                            </Grid>
{/* FIN Date NAISSANCE    */}</Grid> }  
 {/*localiter*/}{Physique &&<Grid  container item xs={12}  direction="column" justifyContent="center" alignItems="flex-start">
 <Controller
                                name="localiter"
                                control={control}
                                defaultValue={RowData.localiter}
                                rules={{ 
                                    required: 'Ce champ doit avoir une valeur' ,
                                }}
                                render={({
                                    field: { onChange, onBlur, value, name, ref },
                                    fieldState: { invalid, isTouched, isDirty, error },
                                    formState,
                                }) =>
                                (
                                <TextField
                                label={ <span className='titreLabel'> Localité </span>}
                                onChange={onChange} value={value}
                                onBlur={onBlur} variant="standard"
                                fullWidth/>
                                )}
                            />
                        <Grid >
                            {errors.localiter && <small className="text-danger">{errors.localiter.message}</small>} 
                        </Grid>
{/* FIN localiter*/}</Grid> }   
{/*adresseDonateur*/}<Grid  container item xs={12}  direction="column" justifyContent="center" alignItems="flex-start">                       
      <Controller
                                name="adresseDonateur"
                                control={control}
                                defaultValue={RowData.adresseDonateur}
                                rules={{ 
                                    required: 'Ce champ doit avoir une valeur' ,
                                }}
                                render={({
                                    field: { onChange, onBlur, value, name, ref },
                                    fieldState: { invalid, isTouched, isDirty, error },
                                    formState,
                                }) =>
                                (
                                <TextField
                                label={ <span className='titreLabel'> Adresse du partenaire </span>}
                                onChange={onChange} value={value}
                                onBlur={onBlur} variant="standard"
                                fullWidth/>
                                )}
                            />
                        <Grid >
                            {errors.adresseDonateur && <small className="text-danger">{errors.adresseDonateur.message}</small>} 
                        </Grid>
{/* FIN adresseDonateur*/}</Grid>    
{/*telephoneDonateur*/}<Grid  container item xs={12}  direction="column" justifyContent="center" alignItems="flex-start">                       
<Controller
                                name="telephoneDonateur"
                                control={control}
                                defaultValue={RowData.telephoneDonateur}
                                rules={{ 
                                    required: 'Ce champ doit avoir une valeur' ,
                                }}
                                render={({
                                    field: { onChange, onBlur, value, name, ref },
                                    fieldState: { invalid, isTouched, isDirty, error },
                                    formState,
                                }) =>
                                (
                                <TextField
                                label={ <span className='titreLabel'> Téléphone du partenaire </span>}
                                onChange={onChange} value={value}
                                onBlur={onBlur} variant="standard"
                                fullWidth/>
                                )}
                            />
                        <Grid >
                            {errors.telephoneDonateur && <small className="text-danger">{errors.telephoneDonateur.message}</small>} 
                        </Grid>
{/* FIN telephoneDonateur*/}</Grid>  
{/*EmailDonateur*/}<Grid  container item xs={12}  direction="column" justifyContent="center" alignItems="flex-start">                       
<Controller
                                name="emailDonateur"
                                control={control}
                                defaultValue={RowData.emailDonateur}
                                rules={{ 
                                    required: 'Ce champ doit avoir une valeur' ,
                                }}
                                render={({
                                    field: { onChange, onBlur, value, name, ref },
                                    fieldState: { invalid, isTouched, isDirty, error },
                                    formState,
                                }) =>
                                (
                                <TextField
                                label={ <span className='titreLabel'> Email du partenaire </span>}
                                onChange={onChange} value={value}
                                onBlur={onBlur} variant="standard"
                                fullWidth/>
                                )}
                            />
                        <Grid >
                            {errors.emailDonateur && <small className="text-danger">{errors.emailDonateur.message}</small>} 
                        </Grid>
{/* FIN EmailDonateur*/}</Grid>
{/*secteurDonateur*/}<Grid item xs={12}>  
<Controller
                        name="secteurDonateur"  
                        control={control}
                        rules={{ required: "Veuillez choisir le secteur" }}
                        defaultValue={RowData.secteurDonateur}
                        render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error },
                            formState,
                        }) =>(
                            <TextField
                            select
                            label={ <span className='titreLabel'> Secteur du partenaire </span>}
                            value={value}
                            onChange={onChange}
                            fullWidth>
                                {status === "success" && dataSecteurs.map((ress) => (
                                <MenuItem key={ress.cuSecteur} value={ress.cuSecteur}>
                                        {ress.nomSecteur}
                                </MenuItem>
                                ))
                                }        
                            </TextField>
                            )
                        }
                        />
                      <Grid >
                      {errors.secteurDonateur && <small className="text-danger">{errors.secteurDonateur.message}</small>}           
                      </Grid>  
{/*FIN SecteurDonateur*/} </Grid>   
{/* publier       */}<Grid item xs={12}>  
                   <Controller
                        name="publierDonateur"  
                        control={control}
                        defaultValue={RowData.publierDonateur}
                        rules={{ required: "Veuillez choisir une valeur" }}
                        render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error },
                            formState,
                        }) =>(
                            <TextField
                            select
                            label={ <span className='titreLabel'> Publier ?  </span>}
                            value={value}
                            onChange={onChange}
                            fullWidth>
                                {Publier.map((ress) => (
                                <MenuItem 
                                   key={ress.key} value={ress.id}
                                >
                                        {ress.Text}
                                </MenuItem>
                                ))
                                }        
                            </TextField>
                            )
                        }
                        />
                      <Grid >
                      {errors.publierDonateur && <small className="text-danger">{errors.publierDonateur.message}</small>}           
                      </Grid>  
{/* FIN publier      */}</Grid> 
<Grid  container direction="row" justifyContent="center" alignItems="center" >
                    
                    <Grid item xs={6} >
                        <Button variant="contained" size="small" color="primary" type="submit" disabled={!isDirty}> Enregistrer</Button>
                    </Grid>
                </Grid> 
               </form> 
            </Grid>
        </div>
    )
}
export default DonateurEditForm
