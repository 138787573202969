export const HOME = "/";
export const LOGIN = "/login";
export const LOGOUT = "/logout";
export const Confirm ='/confirm/:confirmationCode';
//export const Confirm ='/confirm';
export const test ='/test';
export const mainScreen ='/mainScreen'
export const NewUserForm ='/NewUserForm'
export const MenuActivites ='/MenuActivites'
export const MenuSecteurs ='/MenuSecteurs'
export const MenuIndicateur ='/MenuIndicateur'
export const Benevol='/Benevol';
export const dons='/dns'
export const parts='/parts'
export const edpart='/edpart'

