import { clientHTTP } from './clientHTTP';
import { useQuery } from "react-query";
/*
const fortmatResponse = (res) => {
    return JSON.stringify(res, null, 2);
  };
*/
export const DonnateursRQ = () => {
      return  useQuery("Partenaires", GetAllDonateurs,
        {
            staleTime: 60 * 1000, 
            cacheTime: 60 * 1000
        });
  }
export const GetAllDonateurs= async () => {
    //console.log("GetAllSecteurs ")    
    const {data}= await clientHTTP.post(`/api/dnt/all`)
    console.log('DbServices => GetAllDonateurs => data', data)
  return data;
};
export const AddDonateur= async (newRecord ) => {
      //const [,rowData]= queryKey;
      console.log('DbServices => AddDonateur => newRecord', newRecord)
      const {data}= await clientHTTP.post(`/api/dnt/add`,newRecord)
      console.log('DbServices => AddDonateur => data', data)
      return data;
  };
  export const EditDonateur= async (rowData ) => {
      console.log('DbServices => EditDonateur => rowData', rowData)
      const {data}= await clientHTTP.post(`/api/dnt/edit`,rowData)
      console.log('DbServices => EditDonateur => data', data)
      return data;
  };
  export const DelDonateur= async (rowData ) => {
      console.log('DbServices => DelDonateur => rowData', rowData)
      const {data}= await clientHTTP.post(`/api/dnt/del`,rowData)
      console.log('DbServices => DelDonateur => data', data)
      return data;
  };
  export const TypeDonateur= async ( ) => {
      const {data}= await clientHTTP.post(`/api/dnt/type`)
      console.log('DbServices => DelDonateur => data', data)
      return data;
  };
