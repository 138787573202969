import { clientHTTP } from './clientHTTP';
export const TestUserNameExist = async (email) => {
    console.log("Create User Form => CheckUserByEmail ", email)
    const Param={'email':email}
    const response=  await clientHTTP.post(`/api/users/email`,Param)
    console.log("DbServices => Users => TestUserNameExist data",response.data)
    return response.data
  };

  export const InsertUser=async (data)=> {
    console.log("DbServices => Users => InsertUser data receive ",data)
    const response=  await clientHTTP.post(`/api/users/insert`,data)
    console.log("DbServices => Users => InsertUser data",response.data)
    return response.data
  }
  export const SeConnecter=async (data)=> {
    console.log("DbServices => Users => SeConnecter data receive ",data)
    const response=  await clientHTTP.post(`/api/users/connect`,data)
    console.log("DbServices => Users => SeConnecter data",response.data)
    return response.data
  }
  export async function Confirmer(code){
    console.log("DbServices => Users => Confirmer code receive ",code)
    const response=  await clientHTTP.get(`/api/users/confirm/`+code)
    console.log("DbServices => Users => Confirmer => retour",response.data)
    return response.data
  }
  export async function vtoken(token){
    console.log("DbServices => Users => vtoken token receive ",token)
    const response=  await clientHTTP.post(`/api/users/vtoken`,token)
    console.log("DbServices => Users => vtoken => Retour ",response.data)
    return response.data

  }
  export async function getUserByToken(token){
    console.log("DbServices => Users => getUserByToken token receive ",token)
    const response=  await clientHTTP.post(`/api/users/getuserbymail`,token)
    console.log("DbServices => Users => getUserByToken=>  Retour ",response.data)
    return response.data
  }
  export async function getAnimateurByCommune(communeCu){
    console.log("DbServices => Users => SelectAnimateurByCommune communeCu receive ",communeCu)
    const response=  await clientHTTP.post(`/api/users/getAnimateurs`,communeCu)
    console.log("DbServices => Users => communeCu=>  Retour ",response.data)
    return response.data
  }
  export async function GetUserAnimateurById(data){
    console.log("DbServices => Users => GetUserAnimateurById data receive ",data)
    const response=  await clientHTTP.post(`/api/users/animbyid`,data)
    console.log("DbServices => Users => GetUserAnimateurById=>  Retour ",response.data)
    return response.data
  }
  export async function getUserInfoByToken(data){
    console.log("DbServices => Users => getUserInfoByToken data receive ",data)
    const response=  await clientHTTP.post(`/api/users/usinfobytk`,data)
    console.log("DbServices => Users => getUserInfoByToken=>  Retour ",response.data)
    return response.data
  }
  export async function GetListSimpleUser(){
    const response=  await clientHTTP.post(`/api/users/splist`)
    console.log("DbServices => Users => GetListSimpleUser=>  Retour ",response.data)
    return response.data
  }