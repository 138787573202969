import React ,{useState,useEffect} from 'react'
import logo from '../../images/logoCaritas.jpg'
import { useNavigate } from "react-router-dom";
import { Grid, Button} from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import AddReactionSharpIcon from '@mui/icons-material/AddReactionSharp';
import * as urls from './urls'
import "./Entete.css"

const Entete = () => {
    const navigate = useNavigate()
    const TheToken=localStorage.getItem("partgifts")
    const [showDeconecter,SetshowDeconecter]=useState(false)

    useEffect(() =>  {
      if (TheToken) SetshowDeconecter(true)
      else SetshowDeconecter(false)
    },[TheToken]) 

    
    return (
        <div >
            <Grid className="bgc" container spacing={1} direction="row" justifyContent="flex-start" alignItems="center">
                <img src={logo} className="responsive" alt="logo" />
                Base de données des partenaires et des dons
            </Grid>
            <Grid container spacing={1} direction="row" justifyContent="flex-end" alignItems="center">
                <Button variant="contained" startIcon={<AddReactionSharpIcon/>} size="small" color="success"
                onClick={() =>
                {      const OnUser={ "degre":2}
                        navigate(urls.NewUserForm, {state:OnUser })
                }}>    Ajouter Utilisateur
                </Button> 
        
                {showDeconecter && <Button variant="contained" startIcon={<ExitToAppIcon/>} color="error" size="small"
                onClick={() =>
                {   localStorage.removeItem("partgifts") 
                    navigate(urls.LOGOUT)
                }}>    Se Déconnecter
                </Button>}
            </Grid>
        </div>
    )
}
export default Entete
