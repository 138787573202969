import React, { Component } from 'react';
import { render } from 'react-dom';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from "@material-ui/core";

let resolve;
const defaultProps = {
  title: 'Confirmation',
  message: 'Are you sure?',
  variant : "danger"

};
class Confirm extends Component {
  static create(props = {}) {
    const containerElement = document.createElement('div');
    document.body.appendChild(containerElement);
    return render(<Confirm createConfirmProps={props} />, containerElement);
  }

  constructor() {
    super();

    this.state = {
      isOpen: false,
      showConfirmProps: {},
    };

    this.handleCancel = this.handleCancel.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.show = this.show.bind(this);
  }

  handleCancel() {
    this.setState({ isOpen: false });
    
    resolve(false);
  }

  handleConfirm() {
    this.setState({ isOpen: false });
    resolve(true);
  }

  show(props = {}) {
    const showConfirmProps = { ...defaultProps, ...props };
    console.log("props",props)
    console.log("showConfirmProps",showConfirmProps)
    console.log("this.props.createConfirmProps",this.props.createConfirmProps)

    this.setState({ isOpen: true, showConfirmProps });

    
    return new Promise((res) => {
      resolve = res;
    });
    
  }

  render() {
    const { isOpen, showConfirmProps } = this.state;
    const { message, title,variant } = showConfirmProps;
    return (
      <>
      <Dialog open={isOpen}>
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {variant === "danger" && (
          <>
            <Button color="secondary" variant="contained" onClick={this.handleConfirm}>
              Oui, Je confirme
            </Button>
            <Button color="primary" variant="contained" onClick={this.handleCancel} autoFocus>
              Annuler
            </Button>
          </>
        )}

        {variant === "info" && (
          <Button color="primary" onClick={this.handleConfirm}>
            OK
          </Button>
        )}
      </DialogActions>
    </Dialog>
  

      </>
    );
  }
}

//export default Confirm;

export default Confirm.create({});
