import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
//import App from './App';
import { QueryClient, QueryClientProvider } from "react-query";
import reportWebVitals from './reportWebVitals';
//import Start from './compoments/share/start';
//import CreateUserForm from './compoments/share/CreateUserForm';
import StartApp from './compoments/share/StartApp';
import { BrowserRouter as Router } from "react-router-dom";
//import ConnectUserForm from './compoments/share/ConnectUserForm';

//import SelectCommune from './compoments/share/SelectCommune';
/*
import {
  ApolloProvider,
  ApolloClient,
  createHttpLink,
  InMemoryCache
} from '@apollo/client';
*/
const client = new QueryClient();
/*
const httpLink = createHttpLink({
  uri: 'http://localhost:4000'
});

const client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache()
});*/
ReactDOM.render(
  
   <QueryClientProvider client={client}>
     <Router>
       <StartApp/>
     </Router>
    </QueryClientProvider>  
  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
